import { Card, Row, Col } from "antd";
import styled from "styled-components";
import { TeamOutlined } from '@ant-design/icons';

const allRows = [
  [
    { title: 'G.V.G Naidu', role: 'Managing Director - Veetek', role2: 'Principal Consultant - Storage & Sheet Metal Division', icon: <TeamOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }} /> },
    { title: 'G.J Shekar', role: 'Managing Director - ABS & WES', role2: 'Principal Consultant - Fire & Security Division', icon: <TeamOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }} /> },
  ],
]

function Consultants() {
  return(
    <StyledCard style={{ marginBottom: "10%" }}>
      <h1 style={{ textAlign: "center" }}>Our Esteemed Consultants</h1>
      {allRows.map((eachRow) => {
        return (
          <Row style={{ marginRight: "5%", marginLeft: "10%", textAlign: "center" }}>
            {eachRow.map(({ title, role, role2, icon }, index) => { return (
                <Col key={index} style={{ marginBottom: "5%" }} span={12}>
                  {title && title.length ? 
                    <Card className="centered-card rounded custom-card" hoverable={true} >
                      {icon}
                      <div>{title}</div>
                      <br />
                      <div style={{ fontSize: "16px", fontWeight: "500" }}>{role}</div>
                      <div style={{ fontSize: "16px", fontWeight: "500" }}>{role2}</div>
                    </Card> : ''}
                </Col>
                );
            })}
          </Row>
        );
      })}
    </StyledCard>
  );
}

const StyledCard = styled.div`
.custom-card {
  transition: border-color 0.3s; /* Add transition for smooth effect */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 75%;
  margin-left: 5%;
  margin-bottom: 5%;
  margint-right: 5%;
  border-radius:9px !important;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 0px 0px 11px 2px rgba(204, 204, 204, 0.21);
}

.custom-card:hover {
  border-color: red; /* Change border color to red on hover */
  position: absolute;
  border: 2px solid red;
}`;

export default Consultants;
