import { Card, Row, Col } from "antd";
import styled from "styled-components";
import {ProjectOutlined, RadarChartOutlined, DesktopOutlined, AlertOutlined, OrderedListOutlined, UsergroupAddOutlined, PartitionOutlined } from '@ant-design/icons';
import Consultants from "./Consultants";

const allRows = [
  [
    { title: 'Pramond Naidu GV', role: 'Director - BD & Strategry', icon: <ProjectOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }}/> },
    { title: 'Sucharitha Thotapalli', role: 'Finance & HR', icon: <UsergroupAddOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }}/> },
    // { title: 'Pranay Naidu Veera', role: 'Marketing consultant', icon: <RadarChartOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }}/> },
    // { title: 'Dr. Santhan Kumar', role: 'Director', icon: <PartitionOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }}/> },
    { title: 'Shravan Kumar', role: 'Design Manager - Fire & Security', icon: <AlertOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }}/> },
  ],
  [
    // { title: 'Sravan Kumar', role: 'IT Admin', icon: <DesktopOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }}/> },
    // { title: 'Praneetha Parimi', role: 'Sales Co-ordinator', icon: <OrderedListOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }}/> },
    // { title: 'Sucharitha Thotapalli', role: 'Finance & HR', icon: <UsergroupAddOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }}/> },
  ],
  [
    { title: '', role: '', icon: '' },
    { title: 'Sravan Kumar', role: 'IT Admin', icon: <DesktopOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }}/> },
    // { title: 'Shravan Kumar', role: 'Design Manager - Fire & Security', icon: <AlertOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }}/> },
    { title: '', role: '', icon: '' },
  
  ]
]

function OurTeam() {
  return(
    <StyledCard style={{ marginBottom: "9%" }}>
      <h1 style={{ textAlign: "center" }}>Our Team</h1>
      {allRows.map((eachRow) => {
        return (
          <Row style={{ marginRight: "5%", marginLeft: "10%", textAlign: "center" }}>
            {eachRow.map(({ title, role, icon }, index) => { return (
                <Col key={index} style={{ marginBottom: "5%" }} span={8}>
                  {title && title.length ? 
                    <Card className="centered-card rounded custom-card" hoverable={true} >
                      <div>{title}</div>
                      <br />
                      {/* {icon} */}
                      <div style={{ fontSize: "14px", fontWeight: "400" }}>{role}</div>
                    </Card> : ''}
                </Col>
                );
            })}
          </Row>
        );
      })}
      <Consultants />
    </StyledCard>
  );
}

const StyledCard = styled.div`
.custom-card {
  transition: border-color 0.3s; /* Add transition for smooth effect */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120%;
  width: 90%;
  margin-bottom: 5%;
  margint-right: 5%;
  border-radius:9px !important;
  font-size: 18px;
  font-weight: 500;
  box-shadow: 0px 0px 11px 2px rgba(204, 204, 204, 0.21);
}

.custom-card:hover {
  border-color: red; /* Change border color to red on hover */
  position: static;
  border: 2px solid red;
}`;

export default OurTeam;
