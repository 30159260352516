import { Card, Row, Col } from "antd";
import styled from "styled-components";
import { SolutionOutlined, SafetyCertificateOutlined, DeploymentUnitOutlined, SafetyOutlined, ShopOutlined, ShareAltOutlined } from '@ant-design/icons';

const allRows = [
  [
    { title: 'Best Quality', icon: <SolutionOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }} />,description: 'Customer is our strength. All our products are inspected by our quality team and delivered.' },
    { title: 'Integrity', icon: <SafetyCertificateOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }} />,description: 'Our standards are our honest and strong policy ethics.' },
    { title: 'Strategy', icon: <DeploymentUnitOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }} />,description: 'To customer satisfaction and maximise production and minimise loss and waste.' },
  ],
  [
    { title: 'Safety', icon: <SafetyOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }} />,description: 'Each & every work safety is more important. Our company is more awareness about protection.' },
    { title: 'Dealership', icon: <ShopOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }} />,description: 'We are pride to merge the right dealership to serve our nation.' },
    { title: 'Easy Connect', icon: <ShareAltOutlined style={{ fontSize: "220%", color: "#FF0000", marginBottom: "5%" }} />,description: 'Our Pan India connect is advantageous to clients for easy transit and service of all our products.' },
  ]
]

function HomePage() {
  return (
    <StyledCard style={{ marginBottom: "10%" }}>
      <img style={{ display: "block", margin: "auto", marginTop: "2%", marginBottom: "2%" }}  src={require(".././Images/nipunaLogo.png")} alt="" width="10%" height="10%"></img>
      <Card className="rounded" style={{ marginLeft: "250px", marginRight: "250px", cursor: "text", fontSize: "18px", marginBottom: "20px" }} bordered={false} hoverable={true} >
        <div>Our service includes a comprehensive consult to help identify gaps and opportunities, and results in a comprehensive report that includes a project plan with timelines a cost analysis. Our individualized plans are made up of quality services that will help you get there quickly and smoothly. </div>
      </Card>
      <h2 style={{ textAlign: "center" }}>Add QUALITY & TRUST to your Business</h2>
      {allRows.map((eachRow) => {
        return (
          <Row style={{ marginRight: "100px", marginLeft: "100px", textAlign: "center" }}>
            {eachRow.map(({ title, icon, description }) => {
              return (
                <Col span={8}>
                  <Card className="rounded custom-card" style={{ cursor: "text", marginBottom: "10px", marginRight: "10px" }} hoverable={true} >
                    {icon}
                    <div style={{ fontSize: "18px", fontWeight: "500", marginBottom: "5%" }}>{title}</div>
                    <div>{description}</div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        );
      })}
    </StyledCard>
  )
}

const StyledCard = styled.div`
.custom-card {
  transition: border-color 0.3s; /* Add transition for smooth effect */
}

.custom-card:hover {
  border-color: red; /* Change border color to red on hover */
  position: absolute;
  border: 2px solid red;
}
.rounded{
  border-radius:9px !important;
  box-shadow: 0px 0px 11px 2px rgba(204, 204, 204, 0.21);
}`;
export default HomePage;
