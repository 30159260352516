import React from "react";
import ReactDOM from "react-dom/client";
import AboutUs from "./components/AboutUs";
import reportWebVitals from "./reportWebVitals";
import NavBar from "./components/NavBar";
import HomePage from "./components/HomePage";
import ProductsAndServices from "./components/ProductsAndServices";
import OurTeam from "./components/OurTeam";
import { Layout } from "antd";
import ContactUs from "./components/ContactUs";
import { Element } from 'react-scroll';

const { Footer } = Layout;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <NavBar />
    <div style={{ paddingTop: '64px' }}>
      <Element name="home">
        <HomePage />
      </Element>
      <Element name="about-us">
        <AboutUs />
      </Element>
      <Element name="products-services">
        <ProductsAndServices />
      </Element>
      <Element name="our-team">
        <OurTeam />
      </Element>
      <Element name="contact-us">
        <ContactUs />
      </Element>
      <Footer
        style={{
          textAlign: 'center',
          marginTop: "200px"
        }}
      >
        Copyright ©{new Date().getFullYear()} Nipuna Electromech - All Rights Reserved.
      </Footer>
    </div>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
