const ContactUs = () => {
  return (
    <div style={{ textAlign: "center" }}>
      <h1>Contact Us</h1>
      <h2>Nipuna Electromech Private Limited</h2>
      <div style={{ fontSize: "20px" }}>G.V.S & Co. Residency, K R R Nagar, Paugupadu, Kovur, Andhra Pradesh, India</div>
      <div style={{ marginTop: "30px", fontSize: "20px" }}>
        Mobile #: <a href="tel:+919959936013">+91-9959936013</a>
      </div>
      <div style={{ marginTop: "30px", fontSize: "20px" }}>
        E-mail: <a href="mailto:support@nipunaem.com">support@nipunaem.com</a>
      </div>
      <div style={{ marginTop: "30px" }}>
        <iframe
          title="Nipuna Electromech Location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6395.877438822346!2d79.98618695245861!3d14.491800123687083!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4c8d3a0da8efe5%3A0x327eb1f6c8aa6d51!2sNipuna%20Electromech%20Private%20Limited!5e0!3m2!1sen!2sin!4v1717840880847!5m2!1sen!2sin"
          width="100%"
          height="400"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
}

export default ContactUs;
