import { Row, Col, Card } from "antd";
import styled from 'styled-components';

function AboutUs() {
  return (
    <div>
      <StyledCard style={{ marginBottom: "10%" }}>
        <h1 style={{ textAlign: "center" }}>About Us</h1>
        <Row className="padding-1" type="flex" align="middle" justify="start">
          <Col style={{ paddingLeft: '5%' }} xs={24} sm={24} md={11} lg={11} xl={11}>
            <img src={require(".././Images/whoWeAre.jpg")} alt="" width="100%" height="100%"></img>
          </Col>
          <Col xs={24} sm={24} md={13} lg={13} xl={13}>
            <Card className="card-padding-10 margin-t-2" style={{ marginLeft: "13%", cursor: "text", paddingRight: "5%" }} bordered={false} >
              <div style={{ fontSize: "18px", fontWeight: "500"}}>WHO WE ARE?</div>
              <br/>
              <div>Nipuna Electromech established in the year 2021 by Industry Experts in different backgrounds, has its registered offices in Hyderabad and Nellore. Though we have only two registered offices, we tend to expand our supply network through-out the country with staff supporting us from cities like, Chennai, Delhi, Kanpur, Lucknow and Gurugram. With the usage experience of the products we deal with our company has succeeded towards servicing to customers in the field of Fire Protections Services and Storage Solutions oriented industrials projects.</div>
              <br/>
              <div>We are the Distributors of vast variety of LEHRY make CI/DI/Brass/SS valves for Fire Fighting, Plumbing & HVAC Projects. Our wide range of valves also find their way to many Industrial Applications too. Lehry Valves with its presence in the market for over 5 Decades, has been evolving to be the market leader in Valves Sector. With its new state-of-art manufacturing facility in Chennai, LEHRY VALVES stand as the market leader for high quality Forged Brass Ball Valves. It has its presence in many government project across India including Chennai Metro, Mumbai Metro, Defense Sector, etc.  </div>
              {/* <br/> */}
              {/* <div>Apart from the Valves, Our Storage Solutions division deals with all kinds of storage racking like Heavy Duty Racks, Cantilever Racks, Custom Designed Mezzanine Racks for maximizing the utility of space. Our collaboration with 25 Years old experts, VEETEK STOREMATE, trusted manufacturer based for many prominent companies across a wide range of sectors, makes our storage solutions division, THE BEST in all the aspects of racking business, especially in Quality and Customer Satisfaction. </div> */}
            </Card>
          </Col>
        </Row>
        <Row className="padding-1" type="flex" align="middle" justify="start">
          <Col xs={24} sm={24} md={13} lg={13} xl={13}>
            <Card className="card-padding-10 margin-t-2" style={{ marginRight: "10%", cursor: "text", paddingLeft: "8%" }} bordered={false} >
              <div style={{ fontSize: "18px", fontWeight: "500"}}>Why Us?</div>
              <br/>
              <div>Nipuna Electromech established in the year 2021 by Industry Experts in different backgrounds, has its registered offices in Hyderabad and Nellore. Though we have only two registered offices, we tend to expand our supply network through-out the country with staff supporting us from cities like, Chennai, Delhi, Kanpur, Lucknow and Gurugram. With the usage experience of the products we deal with our company has succeeded towards servicing to customers in the field of Fire Protections Services and Storage Solutions oriented industrials projects.</div>
              <br/>
              <div>We are the Distributors of vast variety of LEHRY make CI/DI/Brass/SS valves for Fire Fighting, Plumbing & HVAC Projects. Our wide range of valves also find their way to many Industrial Applications too. Lehry Valves with its presence in the market for over 5 Decades, has been evolving to be the market leader in Valves Sector. With its new state-of-art manufacturing facility in Chennai, LEHRY VALVES stand as the market leader for high quality Forged Brass Ball Valves. It has its presence in many government project across India including Chennai Metro, Mumbai Metro, Defense Sector, etc.  </div>
              {/* <br/> */}
              {/* <div>Apart from the Valves, Our Storage Solutions division deals with all kinds of storage racking like Heavy Duty Racks, Cantilever Racks, Custom Designed Mezzanine Racks for maximizing the utility of space. Our collaboration with 25 Years old experts, VEETEK STOREMATE, trusted manufacturer based for many prominent companies across a wide range of sectors, makes our storage solutions division, THE BEST in all the aspects of racking business, especially in Quality and Customer Satisfaction. </div> */}
            </Card>
          </Col>
          <Col style={{ paddingRight: '5%' }} xs={24} sm={24} md={11} lg={11} xl={11}>
            <img src={require(".././Images/ourValues.jpg")} alt="" width="100%" height="100%"></img>
          </Col>
        </Row>
      </StyledCard>
      <br />
    </div>
  )
}

const StyledCard = styled.div`
.rounded{
  border-radius:9px !important;
  box-shadow: 0px 0px 11px 2px rgba(204, 204, 204, 0.21);
}
.card-padding-10{
  .ant-card-body{
    padding:10px !important;
  }
}
.margin-t-2{
  margin-top:20px !important;
}
.padding-5{
  padding:100px !important;
}
.margin-2{
  margin:20px !important;
}`;

export default AboutUs;
