import React from "react";
import styled from "styled-components";
import { Layout, Menu } from "antd";
import { Link } from 'react-scroll';
const { Header } = Layout;

const items = [
  { key: '1', label: 'Home', to: 'home' },
  { key: '2', label: 'About Us', to: 'about-us' },
  { key: '3', label: 'Our Team', to: 'our-team' },
  { key: '4', label: 'Products & Services', to: 'products-services' },
  { key: '5', label: 'Contact Us', to: 'contact-us' },
];

const NavBar = () => {
  return (
    <StyledMenu>
      <Layout>
        <Header
          style={{
            position: 'fixed',
            zIndex: 1,
            width: '100%',
            display: "flex",
            justifyContent: "center",
            alignItems: 'center',
            backgroundColor: "#ffffff",
          }}
        >
          <Menu
            className="menu-color"
            mode="horizontal"
            style={{
              display: 'flex',
              justifyContent: 'center',
              flex: 1,
            }}>
            {items.map(item => (
              <Menu.Item key={item.key} className="menu-item">
                <Link 
                  to={item.to} 
                  smooth={true} 
                  duration={500}
                  spy={true}
                  activeClass="active"
                >
                  {item.label}
                </Link>
              </Menu.Item>
            ))}
          </Menu>
        </Header>
      </Layout>
    </StyledMenu>
  );
};

const StyledMenu = styled.div`
.menu-color {
  .menu-item {
    font-size: 110%;
    color: #0D0C0C;
  }
  .menu-item .active {
    color: #FF0000;
    &::after {
      border-bottom-color: #FF0000;
    }
  }
  .menu-item:hover {
    &::after {
      border-bottom-color: #FF0000;
    }
  }
}
`;

export default NavBar;
