import React, { useState } from "react";
import styled from "styled-components";
import { Card } from 'antd';

function ProductsAndServices() {
  const [showFireSecurity, setShowFireSecurity] = useState(false);
  const [showStorageSolutions, setShowStorageSolutions] = useState(false);

  const toggleFireSecurity = () => setShowFireSecurity(!showFireSecurity);
  const toggleStorageSolutions = () =>
    setShowStorageSolutions(!showStorageSolutions);

  const fireSecurityChildren = [
    "Lehry Valves",
    "Easyflex Expansion Joints",
    "MEP Contracting (Specialised in Design & Execution as per NBC)",
  ];

  const storageSolutionsChildren = ["Racking", "Metal Furniture"];

  return (
    <StyledServices>
      <h1 style={{ textAlign: "center" }}>Products And Services</h1>
      <div className="container">
        <div className="parent-container">
          <Card className="parent-box" onClick={toggleFireSecurity}>
            Fire & Security
          </Card>
          {showFireSecurity && (
            <div className="children-wrapper">
              <div className="vertical-line"></div>
              <div className="fs-horizontal-line"></div>
              <div className="child-container">
                {fireSecurityChildren.map((child, index) => (
                  // <div key={index} className="child-box-wrapper">
                    // <div className="vertical-line-child"></div>
                  //   <div className="child-box">{child}</div>
                  // </div>
                  <div key={index} className="child-box-wrapper ">
                    <div className="vertical-line-child"></div>
                    <Card
                      key={index}
                      hoverable
                      className="child-box"
                    >{child}</Card>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* <span style={{ paddingRight: "20px" }} /> */}
        <div className="parent-container">
          <Card className="parent-box" onClick={toggleStorageSolutions}>
            Storage Solutions
          </Card>
          {showStorageSolutions && (
            <div className="children-wrapper">
              <div className="vertical-line"></div>
              <div className="ss-horizontal-line"></div>
              <div className="child-container">
                {storageSolutionsChildren.map((child, index) => (
                  // <div key={index} className="child-box-wrapper">
                  //   <div className="vertical-line-child"></div>
                  //   <div className="child-box">{child}</div>
                  // </div>
                  <div key={index} className="child-box-wrapper">
                    <div className="vertical-line-child"></div>
                    <Card
                      key={index}
                      className="child-box"
                      hoverable
                    >{child}</Card>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </StyledServices>
  );
}

const StyledServices = styled.div`
  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    margin-top: 50px;
    position: relative;
  }

  .parent-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10%;
    position: relative;
  }

  .parent-box {
    width: 250px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    cursor: pointer;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
  }

  .parent-box:hover {
    border-color: red; /* Change border color to red on hover */
    position: static;
    border: solid red;
  }

  .vertical-line {
    width: 2px;
    height: 87px;
    background-color: #000;
    position: relative;
    top: 100%; 
    left: 50%;
    transform: translateX(-50%);
  }

  .fs-horizontal-line {
    width: 100%;
    max-width: 441px;
    height: 2px;
    background-color: #000;
    position: absolute;
    top: 208px;
    left: 109px;
  }

  .ss-horizontal-line {
    width: 100%;
    max-width: 220px;
    height: 2px;
    background-color: #000;
    position: absolute;
    top: 208px;
    left: 110px;
  }

  .child-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-top: 20px;
    width: 100%;
    position: relative;
  }

  .child-box-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    margin: 10px;
  }

  .vertical-line-child {
    width: 2px;
    height: 60px;
    background-color: #000;
    position: relative;
    margin-bottom: -17%;
    transform: translateY(-50%);
  }

  .child-box {
    width: 100%;
    margin: 10px;
    position: absolute;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 15px;
    text-align: center 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s, transform 0.3s;
  }

  .child-box:hover {
    border-color: red; /* Change border color to red on hover */
    position: absolute;
    border: 2px solid red;
  }

  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: center;
    }

    .child-container {
      flex-direction: column;
      align-items: center;
    }

    .parent-box, .child-box {
      width: 200px;
    }

    .child-box-wrapper {
      width: auto;
    }
  }
`;

export default ProductsAndServices;
